import React from 'react';

import { Router } from '@reach/router';

import NotFound from '@components/Routing/NotFound';
import ResumeEdit from '@presenters/web/pages/Profile/Edit/Resume';

// import EditIndividualProgram from '@domui-presenters/web/pages/Profile/Edit/Programs/EditIndividualProgram';

import RequireLogin from '@domui-components/Auth/RequireLogin';
import AreasOfExpertiseEdit from '@domui-presenters/web/pages/Profile/Edit/AreasOfExpertise';
import BackgroundEdit from '@domui-presenters/web/pages/Profile/Edit/Background';
import ContactInformationEdit from '@domui-presenters/web/pages/Profile/Edit/ContactInformation';
import PersonalDetailsEdit from '@domui-presenters/web/pages/Profile/Edit/PersonalDetails';
import ProfessionalExperienceEdit from '@domui-presenters/web/pages/Profile/Edit/ProfessionalExperience';
import AddProgram from '@domui-presenters/web/pages/Profile/Edit/Programs/AddProgram';
import EditPrograms from '@domui-presenters/web/pages/Profile/Edit/Programs/EditPrograms';
import ProgramsList from '@domui-presenters/web/pages/Profile/Edit/Programs/ProgramList';
import MyProfile from '@domui-presenters/web/pages/Profile/View/MyProfile';
import ProfileView from '@domui-presenters/web/pages/Profile/View/ProfileView';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

const ProfilePage: React.FC<{ pageContext: { languagePrefix: string } }> = ({
  pageContext: { languagePrefix },
}) => {
  const { t } = useTranslation();
  return (
    <RequireLogin>
      <Helmet
        titleTemplate={`${t('metadata.title.profile', 'Profile')}: %s | ${t(
          'metadata.title.default',
          'My Rotary'
        )}`}
      />
      <Router basepath={`/${languagePrefix}domui`}>
        <MyProfile path="/profile/me" />
        <ProfileView path="/profile/:individualId" />
        <PersonalDetailsEdit
          path="/profile/:individualId/edit-personal"
          individualId="is set from the path"
        />
        <BackgroundEdit path="/profile/:individualId/edit-background" />
        <ContactInformationEdit
          path="/profile/:individualId/edit-contact"
          individualId="is set from the path"
        />
        <ProfessionalExperienceEdit
          path="/profile/:individualId/edit-professional-experience"
          individualId="is set from the path"
        />
        <AreasOfExpertiseEdit
          path="/profile/:individualId/edit-areas-of-expertise"
          individualId="is set from the path"
        />
        <ResumeEdit path="/profile/:individualId/edit-resume" />
        <EditPrograms
          path="/profile/:individualId/edit-programs/*"
          render={({
            individualId,
            individualPrograms,
            programs,
            // refetch,
            sharingPermissionsExtended,
            countries,
          }) => (
            <>
              <ProgramsList
                path="/"
                {...{
                  individualId,
                  individualPrograms,
                  sharingPermissionsExtended,
                  //   refetch,
                }}
              />
              {/* <EditIndividualProgram
                path=":individualProgramId/edit"
                {...{ individualId, programs, individualPrograms, refetch }}
              /> */}
              <AddProgram
                path="add"
                {...{
                  individualId,
                  programs,
                  countries,
                  // refetch
                }}
              />
            </>
          )}
        />
        <NotFound default />
      </Router>
    </RequireLogin>
  );
};

export default ProfilePage;
