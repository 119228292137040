import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';

import AreasOfExpertise from './AreasOfExpertise';
import Background from './Background';
import ContactInformation from './ContactInformation';
import PersonalDetails from './PersonalDetails';
import ProfessionalExperience from './ProfessionalExperience';
// import Resume from './Resume';
import RotaryPrograms from './RotaryPrograms';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import Icon from '@domui-components/Icon';
import { OneColumn } from '@domui-components/Layouts/OneColumn';
import Loading from '@domui-components/Loading';
import { useFetchEditPermission } from '@domui-hooks/iam/useFetchEditPermission';
import { useFetchIndividual } from '@domui-hooks/useFetchIndividual';
import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';
import { getIdFromPath } from '@domui-use-cases/profile/helpers/getIdFromPath';
import { useFetchIndividualPrograms } from 'src/domui/hooks/useFetchIndividualPrograms';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  individualId?: string;
}

const ProfileView: React.FC<Props> = ({ individualId, location }) => {
  const { t } = useTranslation();
  const { addError } = useNotifications();
  const individualData = getClubDistrictFromLocal();
  const requestorId = individualData?.individualInfo?.id;

  const { data, loading, error, fetchIndividual } = useFetchIndividual();
  const {
    data: programs,
    loading: programLoading,
    fetchIndividualPrograms,
  } = useFetchIndividualPrograms();

  const {
    data: iamData,
    loading: iamLOADING,
    // error: IAMError,
    fetchEditPermission,
  } = useFetchEditPermission();

  useErrorHandling(
    t('view-profile.query.error', 'An error occurred.'),
    error !== undefined,
    'profile-view.error'
  );

  const individual = getIdFromPath(location?.pathname);
  const payload = {
    data: [
      {
        target: `/individuals/${individual}`,
      },
    ],
  };

  useEffect(() => {
    fetchEditPermission(requestorId, payload);
  }, [requestorId]);

  useEffect(() => {
    async function getProfileInfo() {
      if (individualId) {
        try {
          fetchIndividual(individualId);
        } catch (error) {
          addError((error as Error).message, { id: 'form.error' });
        }
      }
    }
    async function getPrograms() {
      if (individualId) {
        try {
          fetchIndividualPrograms(individualId);
        } catch (error) {
          addError((error as Error).message, { id: 'form.error' });
        }
      }
    }
    getProfileInfo();
    getPrograms();
  }, [individualId]);

  if (error || !individualId) {
    return <p>error</p>;
  }
  if (!data || loading || programLoading || iamLOADING) {
    return <Loading />;
  }

  const {
    firstName,
    lastName,
    middleName,
    suffix,
    prefix,
    gender,
    photoUri,
    localizedName,
    riIndividualId,
    youthFlag,
    dateOfBirth,
    nfKey,
    emails,
    phones,
    addresses,
    aboutMe,
    expertises,
    professions,
  } = data;

  const handleBackToPreviousPage = () => {
    const previousPathUrl = window.sessionStorage.getItem('previousPagePath');

    if (!previousPathUrl) return window.history.back();

    window.sessionStorage.removeItem('previousPagePath');
    return localizedNavigate(previousPathUrl);
  };

  return (
    <OneColumn className="mb-20">
      <span
        onClick={() => handleBackToPreviousPage()}
        className="inline-flex items-center font-bold text-bright-blue-600 link-styles text-xs leading-xs-heading mb-8"
      >
        <Icon name="arrows/left" className="mr-2" size="12" />
        {t('view-profile.back-link', 'Back to previous page')}
      </span>
      <PersonalDetails
        {...{
          firstName,
          lastName,
          middleName,
          suffix,
          prefix,
          gender,
          photoUri,
          localizedName,
          riIndividualId,
          dateOfBirth,
          youthFlag,
          nfKey,
          individualId,
          hasEditPermission: iamData?.contactInformation || false,
        }}
      />
      <ContactInformation
        {...{
          emails,
          phones,
          addresses,
          individualId,
          hasEditPermission: iamData?.contactInformation || false,
        }}
      />
      <Background
        {...{
          aboutMe,
          individualId,
          hasEditPermission: iamData?.background || false,
        }}
      />
      {/* <Resume
        {...{
          // resumeRoles,
          // resumeRecognitions,
          // resumeSponsorships,
          hasEditPermission: true,
          individualId,
        }}
      /> */}
      <RotaryPrograms
        {...{
          programs,
          individualId,
          hasEditPermission: iamData?.rotaryPrograms || false,
        }}
      />
      <ProfessionalExperience
        {...{
          individualId,
          professions,
          hasEditPermission: iamData?.professionalExperience || false,
        }}
      />
      <AreasOfExpertise
        {...{
          individualId,
          expertises,
          hasEditPermission: iamData?.areasOfExpertise || false,
        }}
      />
    </OneColumn>
  );
};

export default ProfileView;
