import { flatten } from 'lodash';

import { DynamicData } from '@domui-domain/type';

export const selectFieldsFromProgramDefinition = (
  programDefinition: DynamicData
): DynamicData[] =>
  flatten(
    programDefinition?.fieldGroups.map(
      (fg: { fields: DynamicData }) => fg.fields
    )
  );
