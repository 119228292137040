/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { FieldArray, Form, Formik } from 'formik';

import { Button } from '@components/Button';
import TextField from '@components/Formik/TextField';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import { selectProfessionLabelValue } from './utils';
import AddAnotherButton from '../components/ButtonAddAnother';
import DeleteButton from '../components/ButtonDelete';
import PrimaryRadio from '../components/PrimaryRadio';
import { professionalExperienceValidationSchema } from '../validationSchema';

import { useErrorHandling } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import SharingPermissionSelect from '@domui-components/Formik/Select/SharingPermissionSelect';
import {
  EditProfessionsFormValues,
  FormProfessionItem,
  mapFormValuesToUpdateProfessionalExperienceInput,
  mapProfessionalExperienceDataToFormValues,
} from '@domui-domain/profile';
import { useFetchIndividual } from '@domui-hooks/useFetchIndividual';
import { useUpdateProfessionalExperience } from '@domui-hooks/useUpdateProfessionalExperience';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  individualId: string;
}

const ProfessionalExperienceEdit: React.FC<Props> = ({ individualId }) => {
  const [professionalExpPayload, setProfessionalExpPayload] = useState<
    object
  >();

  const { t } = useTranslation();

  const {
    data: individualData,
    loading: individualLoading,
    error: individualError,
    fetchIndividual,
  } = useFetchIndividual();

  useErrorHandling(
    t('get-professional-experience.error', 'An error occurred.'),
    !!individualError,
    'fetch.error'
  );

  useEffect(() => {
    if (individualId) {
      fetchIndividual(individualId);
    }
  }, [individualId]);

  function findMissingAndCombineValues(
    arr1: any,
    arr2: any,
    key: any,
    actionKey: any,
    actionValue: any
  ) {
    const arr2values = arr2.map((item: { [x: string]: any }) => item[key]);
    const deletedObject = arr1.professions.filter(
      (item: { [x: string]: any }) => !arr2values.includes(item[key])
    );
    if (deletedObject?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      deletedObject.forEach((item: any) => {
        // eslint-disable-next-line no-param-reassign
        item[actionKey] = actionValue.delete;
      });
    }
    const addUpdateMutationValues = arr2.map((value: any) => {
      if (
        value.id === undefined ||
        value.id === 'undefined' ||
        value.id === null ||
        value.id === ''
      ) {
        // eslint-disable-next-line no-param-reassign
        delete value.id;
        return { ...value, [actionKey]: actionValue.add };
      }
      if (value?.id) {
        return { ...value, [actionKey]: actionValue.update };
      }
      return value;
    });
    return [...addUpdateMutationValues, ...deletedObject];
  }

  const handleFormSubmit = async (values: EditProfessionsFormValues) => {
    const mutationValues = mapFormValuesToUpdateProfessionalExperienceInput(
      values
    );

    const newKey = 'action';
    const action = {
      add: 'Add',
      update: 'Update',
      delete: 'Delete',
    };

    const data1 = individualData;
    const data2 = mutationValues.professions;
    const findMissingAndCombineObjects = findMissingAndCombineValues(
      data1,
      data2,
      'id',
      newKey,
      action
    );
    const sharingPermissionId: any =
      values?.sharingPermissionsExtended?.profession?.id;
    const professionalExperiencePayload = {
      dominoId: individualId,
      sharingPermissionId,
      findMissingAndCombineObjects,
    };
    setProfessionalExpPayload(professionalExperiencePayload);
  };

  const {
    loading: updatedLoading,
    error: updatedError,
    updateProfessionalExperience,
  } = useUpdateProfessionalExperience();

  useErrorHandling(
    updatedError?.description
      ? updatedError.description
      : t('update-professional-experience-error', 'An error occurred.'),
    !!updatedError,
    'mutation.error'
  );

  useEffect(() => {
    if (professionalExpPayload) {
      updateProfessionalExperience(professionalExpPayload);
    }
  }, [professionalExpPayload]);

  const handleCancel = () => {
    localizedNavigate(`/domui/profile/${individualId}`);
  };

  if (individualLoading) {
    return <Loading />;
  }

  if (updatedLoading && professionalExpPayload) {
    return <Loading />;
  }

  const formValues = mapProfessionalExperienceDataToFormValues(
    individualData as any
  );

  if (formValues.professionalExperiences.length === 0) {
    formValues.professionalExperiences = [
      {
        occupation: '',
        employer: '',
        position: '',
        isPrimary: true,
        id: '',
      },
    ];
  }

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={formValues}
        onSubmit={handleFormSubmit}
        validationSchema={professionalExperienceValidationSchema(t)}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const selectPrimary = (selectedIndex: number) => {
            setFieldValue(
              'professionalExperiences',
              values.professionalExperiences.map(
                (profession: FormProfessionItem, index: number) =>
                  index === selectedIndex
                    ? { ...profession, isPrimary: true }
                    : { ...profession, isPrimary: false }
              )
            );
          };
          const multipleProfessions = values.professionalExperiences.length > 1;
          const makePrimary = values.professionalExperiences.length < 1;

          return (
            <>
              <LinkPrevious
                path={`/domui/profile/${individualId}`}
                label={t('edit-profile.back-link', 'Profile')}
              />
              <Title>
                {t(
                  'edit-professional-experience.title',
                  'Edit Professional Experience'
                )}
              </Title>
              <div className="desktop:flex desktop:flex-row-reverse mt-10 desktop:mt-24">
                <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg">
                  {formValues && (
                    <SharingPermissionSelect selectName="sharingPermissionsExtended.profession.id" />
                  )}
                </div>
                <div className="desktop:flex-2 desktop:mr-24">
                  <Form className="max-w-lg">
                    <FieldArray
                      name="professionalExperiences"
                      render={({ push, remove }) => (
                        <>
                          {values.professionalExperiences.map(
                            (profession, i) => (
                              <div
                                key={`${profession}`}
                                className="pb-8 mb-8 border-b border-gray-300 border-dotted"
                              >
                                <TextField
                                  name={`professionalExperiences.${i}.occupation`}
                                  label={`${t(
                                    'edit-professional-experience.occupation',
                                    'Occupation'
                                  )}<span class="sr-only"> ${i + 1}</span>`}
                                />
                                <TextField
                                  name={`professionalExperiences.${i}.employer`}
                                  label={`${t(
                                    'edit-professional-experience.employer',
                                    'Employer'
                                  )}<span class="sr-only"> ${i + 1}</span>`}
                                />
                                <TextField
                                  name={`professionalExperiences.${i}.position`}
                                  label={`${t(
                                    'edit-professional-experience.position',
                                    'Position'
                                  )}<span class="sr-only"> ${i + 1}</span>`}
                                />
                                <div className="mt-8 flex">
                                  <PrimaryRadio
                                    checked={profession.isPrimary}
                                    id={`primary-radio-${i}`}
                                    name="primaryOccupation"
                                    onClick={() => selectPrimary(i)}
                                    label={`<span class="sr-only">${selectProfessionLabelValue(
                                      values.professionalExperiences[i]
                                    )} </span> ${t(
                                      'edit-professional-experience.primary-label',
                                      'Primary'
                                    )}`}
                                  />
                                  {((multipleProfessions &&
                                    !profession.isPrimary) ||
                                    !multipleProfessions) && (
                                    <DeleteButton onClick={() => remove(i)} />
                                  )}
                                </div>
                              </div>
                            )
                          )}
                          <AddAnotherButton
                            label={t(
                              'edit-professional-experience.add-label',
                              'Add another occupation'
                            )}
                            onClick={() => {
                              push({
                                occupation: '',
                                employer: '',
                                position: '',
                                isPrimary: makePrimary,
                              });
                            }}
                          />
                          <Button
                            full
                            className="mt-16 mb-6"
                            clickHandler={() => {
                              handleSubmit();
                            }}
                          >
                            {t(
                              'edit-professional-experience.form.submit-label',
                              'Save Changes'
                            )}
                          </Button>
                          <Button
                            type="button"
                            full
                            text
                            clickHandler={handleCancel}
                          >
                            {t(
                              'edit-professional-experience.form.cancel-label',
                              'Cancel'
                            )}
                          </Button>
                        </>
                      )}
                    />
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </OneColumn>
  );
};

export default ProfessionalExperienceEdit;
