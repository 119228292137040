import { useEffect } from 'react';

import { useSharingPermissions } from '@domui-hooks/useSharingPermissions';

export const useSharingPermissionsData = () => {
  const { data, loading, error, sharingPermissions } = useSharingPermissions();

  useEffect(() => {
    sharingPermissions();
  }, []);

  return {
    data,
    loading,
    error,
  };
};
