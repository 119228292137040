/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { DomuiError } from '@domui-domain/type';
import Metadata from '@domui-utils/workflow/metadata';

// Custom hook to handle API calls
export const useSharingPermissions = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(null);

  const sharingPermissions = async () => {
    // Clear error before new request
    try {
      setLoading(true);
      setError(null);
      const wfService = new Metadata();
      const response = await wfService.getSharingPermissionData();
      setData(response);
      setLoading(false);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, sharingPermissions };
};
