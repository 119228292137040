// Libs
import React from 'react';

// Types
// import { ProgramDefinition } from '@typings/graphql';

// Hooks

// Components
import ProgramField from './ProgramField';

import { DynamicData } from '@domui-domain/type';

import { useTranslation } from '@external/react-i18next';

interface Props {
  programDefinition: DynamicData;
  countries: DynamicData;
}

const EditProgramFields: React.FC<Props> = ({
  programDefinition,
  countries,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {programDefinition?.fieldGroups.map((group: DynamicData) => {
        return (
          <div key={group?.translationKey}>
            <h3 className="uppercase">
              {t('programs.field-group.*', '{fieldGroup}', {
                fieldGroup: {
                  key: group?.translationKey,
                  default: group?.defaultTranslation,
                },
                context: 'fieldGroup',
              })}
            </h3>
            {group?.fields.map((f: DynamicData) => (
              <ProgramField
                key={f?.fieldId}
                {...f}
                countries={f?.type === 'Options' ? countries : null}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default EditProgramFields;
