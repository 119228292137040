// Libs
import React, { useState } from 'react';

// import { QueryResult } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

// Components
import { Button } from '@components/Button';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import DeleteConfirmationModal from '@components/Modals/DeleteConfirmationModal';
import Title from '@components/Title';

import ProgramsTable from './ProgramsTable';
import Info from '../components/Info';
// import { EditProgramsSharingPermissions } from '../EditPrograms';

// Hooks
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useDeleteProgram } from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import SharingPermissionSelect from '@domui-components/Formik/Select/SharingPermissionSelect';
import { DynamicData } from '@domui-domain/type';
import { useUpdateProgramsSharingPermissions } from '@domui-hooks/useUpdateProgramsSharingPermissions';

import { useTranslation } from '@external/react-i18next';

// Utils

interface Props extends RouteComponentProps {
  individualId: string;
  individualPrograms: DynamicData;
  sharingPermissionsExtended: string;
}
interface FormValues {
  sharingPermissionsExtended: string;
}

const ProgramsList: React.FC<Props> = ({
  individualId,
  individualPrograms,
  sharingPermissionsExtended,
  //  refetch,
}) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [deleteIndividualProgramId, setDeleteIndividualProgramId] = useState(
    ''
  );
  // const [sharingPermissionData, setSharingPermissionData] = useState([]);
  const [deleteProgram] = useDeleteProgram();
  // useEffect(() => {
  //   async function fetchSharingPermissionData() {
  //     try {
  //       const wfService = new Metadata();
  //       const response = await wfService.getSharingPermissionData();
  //       setSharingPermissionData(response);
  //     } catch (error) {
  //       addError((error as Error).message, { id: 'fetch.error' });
  //     }
  //   }
  //   fetchSharingPermissionData();
  // }, []);

  const handleDelete = async () => {
    try {
      await deleteProgram({
        variables: {
          individualId,
          individualProgramId: deleteIndividualProgramId,
        },
      });
      addSuccess(
        t(
          'edit-profile.programs.delete-successful',
          'Program deleted successfully.'
        ),
        { id: 'form.success' }
      );
    } catch (error) {
      addError(
        t('edit-profile.programs.deletion-error', 'Could not delete program'),
        { id: 'form.error' }
      );
    } finally {
      setIsConfirmationModalOpen(false);
      setDeleteIndividualProgramId('');
    }
  };
  const handleEdit = (individualProgramId: string) => {
    localizedNavigate(
      `/domui/profile/${individualId}/edit-programs/${individualProgramId}/edit`
    );
  };
  const handleAdd = () => {
    localizedNavigate(`/domui/profile/${individualId}/edit-programs/add`);
  };

  const {
    loading: updatedLoading,
    error: updatedError,
    UpdateProgramsSharingPermissions, // Corrected property name
  } = useUpdateProgramsSharingPermissions();

  useErrorHandling(
    updatedError?.description
      ? updatedError.description
      : t('update-sharingPermission.error', 'An error occurred.'),
    !!updatedError,
    'update-sharingPermission.error'
  );

  const handleFormSubmit = async (values: FormValues) => {
    await UpdateProgramsSharingPermissions({
      id: individualId,
      sharingPermissionId: values.sharingPermissionsExtended,
    });
    // await refetch();
  };

  if (!updatedLoading) {
    return <Loading />;
  }

  return (
    <OneColumn className="mb-20">
      <LinkPrevious
        path={`/domui/profile/${individualId}`}
        label={t('edit-profile.back-link', 'Profile')}
      />
      <Title>
        {t('edit-profile.edit-programs.title', 'Edit Rotary Programs')}
      </Title>
      <Info />
      <Formik
        initialValues={{
          sharingPermissionsExtended,
        }}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {() => (
          <div className="flex justify-end">
            {sharingPermissionsExtended && (
              <Form className="flex-1 mt-14 mb-16 max-w-sm">
                <SharingPermissionSelect
                  selectName="sharingPermissionsExtended"
                  // sharingPermissionData={sharingPermissionData}
                />
                <Button full secondary small className="mt-1">
                  {t(
                    'edit-profile.programs.form.submit-sharing-permissions',
                    'Save Changes'
                  )}
                </Button>
              </Form>
            )}
          </div>
        )}
      </Formik>
      <ProgramsTable
        handleDelete={individualProgramId => {
          setDeleteIndividualProgramId(individualProgramId);
          setIsConfirmationModalOpen(true);
        }}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        programs={individualPrograms}
      />
      <DeleteConfirmationModal
        isOpen={isConfirmationModalOpen}
        closeModal={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleDelete}
        title={t(
          'edit-profile.programs.confirm-deletion.title',
          'Are you sure you want to delete this program?'
        )}
      />
    </OneColumn>
  );
};

export default ProgramsList;
