/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Select, { SelectOptions, useOptions } from '../Select';

import { useSharingPermissionsData } from '@domui-repositories/profile/index';

import { useTranslation } from '@external/react-i18next';

const SharingPermissionsSelect: React.FC<{
  selectName: string;
  isClubOfficer?: boolean;
}> = ({ selectName, isClubOfficer }) => {
  const { t } = useTranslation();

  const isEmailSelect = selectName.includes('email');

  const useLocalSharingPermissions: useOptions = () => {
    const { data, error, loading } = useSharingPermissionsData();
    const options: SelectOptions =
      data
        ?.filter(
          (label: any) =>
            !(isEmailSelect && isClubOfficer && label.value === 'No Sharing')
        )
        .map((item: any) => ({
          value: item?.id,
          label: item?.value,
        })) || [];
    return { options, error: error?.message, loading };
  };

  const label = t(
    'profile_edit.form.sharing-permission-label',
    'Who Can See This'
  );
  const tooltipText =
    isEmailSelect && isClubOfficer
      ? t(
          'profile-edit.form.sharing-permission-email-club-officers',
          "Club officer's email should always be seen by members under same club"
        )
      : t(
          'profile-edit.form.sharing-permission-help-text',
          'This tooltip will explain what this field does and how it should be used.'
        );

  return (
    <Select
      name={selectName}
      label={label}
      options={useLocalSharingPermissions}
      blueBg
      // TODO Update tooltip text.
      tooltipText={tooltipText}
    />
  );
};

export default SharingPermissionsSelect;
