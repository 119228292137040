/* eslint-disable react/no-array-index-key */
// Libs
import React from 'react';

// Components
import Divider from '@domui-components/Divider';
import LinkEdit from '@domui-components/LinkEdit';
import VerifiedTag from '@domui-components/VerifiedTag';
import { DynamicData } from '@domui-domain/type';

import { useTranslation } from '@external/react-i18next';

type Props = {
  programs: DynamicData;
  individualId: string | undefined;
  hasEditPermission: boolean;
};

const RotaryPrograms: React.FC<Props> = ({
  programs,
  individualId,
  hasEditPermission,
}) => {
  const { t } = useTranslation();
  const programsList: object[] = programs?.programs;

  return (
    <>
      <Divider desktopOnly />
      <div className="flex justify-between items-start">
        <h2>{t('view-profile.programs.title', 'Rotary Programs')}</h2>
        {hasEditPermission && (
          <LinkEdit
            path={`/domui/profile/${individualId}/edit-programs`}
            component={t('view-profile.programs.title', 'Rotary Programs')}
          />
        )}
      </div>
      {!!programsList?.length &&
        programsList.map((program: DynamicData) => (
          <div key={`${program?.alumniExperienceId}`} className="mb-8">
            <h3 className="h5">{program?.program}</h3>
            {program?.displayFields.map((field: DynamicData) => (
              <p
                key={`${field?.fieldName}`}
                className="mb-0"
              >{`${field?.fieldDescription}: ${field?.fieldValue}`}</p>
            ))}
            <VerifiedTag pending={!program?.verified} />
          </div>
        ))}
    </>
  );
};

export default RotaryPrograms;
